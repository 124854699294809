<template keep-alive>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Search',
}
</script>

<style scoped lang="scss"></style>
